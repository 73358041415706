import { Mutex } from 'async-mutex';
import axios, { AxiosInstance, AxiosRequestConfig, CancelTokenSource } from 'axios';
import sha1 from 'sha1';

import { getAppConfig } from './appConfig';
import Logger from './Logger';
import StorageHelper from './StorageHelper';
import TokenHelper from './token';
import { getHeaderURLValue } from './validators/getHeaderURLValue';
import userLocale, { setAppropriateLocale } from '../aqua-delivery-web-client-ui/i18n';
import { Services } from '../entities/AppConfigEntity';
import { PartnerActions } from '../redux/actions/partner';
import store from '../redux/store';

const { url, defaultToken, services } = getAppConfig();

const mutex = new Mutex();

const requests: Record<string, { cancelToken: CancelTokenSource }> = {};

const getToken = () => {
    let token = null;
    const localToken = TokenHelper.getToken();

    if (defaultToken) {
        token = defaultToken;
    }

    if (localToken) {
        token = localToken;
    }

    return token ? `Bearer ${token}` : undefined;
};

const cancelToken = (config: AxiosRequestConfig) => {
    if (config.cancelToken !== undefined) {
        const cancelToken = axios.CancelToken.source();
        config.cancelToken = cancelToken.token;
        const hash = sha1(config.url as any);
        //@ts-ignore
        config.hash = hash;

        if (requests[hash]) {
            requests[hash].cancelToken.cancel(config.url);
        }
        requests[hash] = {
            cancelToken,
        };
    }
};

const enrichPartner = async () => {
    // @ts-ignore
    return store.dispatch(PartnerActions.partnerEnrichToken());
};

type TRefreshResponse = {
    token: string;
    refresh_token: string;
};

export const refreshToken = async () => {
    const accessErrorHandler = () => {
        StorageHelper.clearLocalStorage();
        localStorage.setItem('accessError', 'true');
        window.location.reload();
    };

    const rToken = TokenHelper.getRefreshToken();

    if (!rToken) {
        accessErrorHandler();
    }

    const url = `${services.auth.domain}${services.auth.refreshTokenUrl}`;

    try {
        const { data } = await axios.post<TRefreshResponse>(url, {
            refresh_token: rToken,
        });
        TokenHelper.setTokens({
            token: data.token,
            refresh: data.refresh_token,
        });

        return data.token;
    } catch (e) {
        accessErrorHandler();

        return null;
    }
};

const source = 'site';

export const getLocale = () => {
    if (userLocale.locale === localStorage.getItem('language')) {
        return userLocale.locale;
    }

    return setAppropriateLocale(navigator.language.split('-')[0]);
};

type ClientsType = 'default' | keyof Services;

const additionalHeadersToService: Partial<Record<ClientsType, Object>> = {
    cart: {
        'X-Source': source,
    },
};

class HttpClients {
    clients: Map<ClientsType, AxiosInstance> = new Map();

    defaultUrl: string | undefined;

    async responseError(error: any) {
        const originRequest = error.config;

        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }
        await mutex.waitForUnlock();

        Logger.error({
            data: {
                url: getHeaderURLValue(error?.config),
                method: error?.config?.method.toUpperCase(),
                status_code: error.response?.status,
                dateFinish: new Date(Date.now()).toUTCString(),
                xz_config: error.config,
                x_data: JSON.stringify(error.response?.data),
                message: error?.message,
            },
            category: 'http-error',
            type: 'http',
        });

        if (error.response && error.response.status === 401) {
            if (!mutex.isLocked()) {
                const release = await mutex.acquire();
                return refreshToken()
                    .then(async () => {
                        return enrichPartner().then(() => {
                            originRequest.headers.Authorization = getToken();

                            return this.getClient().request(originRequest);
                        });
                    })
                    .finally(() => {
                        release();
                    });
            } else {
                await mutex.waitForUnlock();
            }
        } else if (error.response && error.response.status === 422 && !TokenHelper.hasPartner()) {
            return enrichPartner().then(() => {
                originRequest.headers.Authorization = getToken();

                return this.getClient().request(originRequest);
            });
        } else {
            throw error;
        }
    }

    getClient(service: ClientsType = 'default'): AxiosInstance {
        if (this.clients.has(service)) {
            return this.clients.get(service)!;
        }

        const baseURL =
            service === 'default' ? this.defaultUrl : services[service as keyof Services].domain;

        if (!baseURL) {
            throw Error('no service domain and default url');
        }

        const additionalHeaders = additionalHeadersToService[service] ?? {};

        const newClient = axios.create({
            baseURL: baseURL,
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json',
                ...additionalHeaders,
            },
        });

        newClient.interceptors.request.use(
            config => {
                cancelToken(config);

                config.headers = {
                    Authorization: getToken(),
                    ...config.headers,
                    'Accept-Language': getLocale(),
                };

                Logger.info({
                    data: {
                        url: getHeaderURLValue(config),
                        method: config?.method?.toUpperCase() ?? null,
                        dateStart: new Date(Date.now()).toUTCString(),
                        x_data: config.data,
                        x_json_data: JSON.stringify(config.data),
                        token: config.headers.Authorization,
                    },
                    category: 'http-request',
                    type: 'http',
                });

                return config;
            },
            error => {
                return Promise.reject(error);
            },
        );

        newClient.interceptors.response.use(
            config => {
                //@ts-ignore
                const hash = config.config.hash;
                if (requests[hash]) {
                    delete requests[hash];
                }

                Logger.info({
                    data: {
                        url: getHeaderURLValue(config.config),
                        method: config.config.method?.toUpperCase(),
                        status_code: config.status,
                        x_response: config.data,
                        x_data: JSON.stringify(config),
                    },
                    category: 'http-response',
                    type: 'http',
                });

                return config;
            },
            error => this.responseError(error),
        );

        this.clients.set(service, newClient);

        return newClient;
    }

    setDefaultUrl(url: string) {
        this.defaultUrl = url;
    }
}

const httpClientServices = new HttpClients();
httpClientServices.setDefaultUrl(url);

export { httpClientServices };
