import { message } from 'antd';

import { TUserData } from './user';
import TokenHelper from '../../utils/token';
import { TAction } from '../store';
import { StorageKeys } from '../../utils/enums/StorageKeys';

type TEnrichToken = {
    '@context': string;
    '@id': string;
    '@type': string;
    id: number;
    clientId: string | null;
    token: string;
};

type ClientTypes = {
    clientEnrichToken: () => TAction<Promise<string | void>>;
};

export const ClientActions: ClientTypes = {
    clientEnrichToken() {
        return async (_, getState, { httpClientServices }) => {
            const { data } = getState().user;
            const { selectedAddress } = getState().addresses;
            const { info } = getState().company;

            const isAuth = data !== null && TokenHelper.isAnon();

            if (isAuth && selectedAddress && info) {
                const { uuid, phone, username, email } = data as TUserData;
                const body = {
                    externalUserId: uuid,
                    externalLocationId: selectedAddress.uuid ?? null,
                    username: email,
                    phone: `${info.phone_code}${phone}`,
                    firstName: username,
                };

                return httpClientServices
                    .getClient('client')
                    .post<TEnrichToken>('/client/enrich_token_requests', body, {
                        // headers: {
                        //     // Authorization: `Bearer ${data.token}`,
                        //     'device-info': JSON.stringify({
                        //         uniqueDeviceId: localStorage.getItem(StorageKeys.deviceId),
                        //     }),
                        // },
                    })
                    .then(({ data }) => {
                        TokenHelper.setTokens({ token: data.token });

                        console.log(data)

                        return data.token;
                    })
                    .catch(e => {
                        if (e.response && e.response.status !== 404) {
                            message.error(e.message);
                        }
                        TokenHelper.cleanTokens();
                    });
            }
        };
    },
};
