const login = {
    title: 'Giriş / Kayıt',
    titleBonus: 'Sipariş vermek ve ikramiye almak için giriş yapın',
    content: {
        termsOfUse: {
            beforeLink: 'Devam ederek kişisel verilerin işlenmesine ',
            link: 'izin',
            afterLink: ' veriyorum',
        },
        phoneNumber: {
            article:
                'Rezervasyon yapmak, ikramiye almak ve daha fazlasını yapmak için giriş yapın veya kaydolun',
        },
        smsCode: {
            smsText: 'Belirtilen numaraya alınan SMS kodunu girin',
            phoneCallText: 'Arayan numaranın son {{codeLength}} hanesini girin',
            phoneCallTextSubtitle:
                'Arama gelmediyse, {{delayTime}} saniye içinde. Size otomatik olarak bir yetkilendirme kodu içeren bir sms gönderilecektir',
            emailText: 'Belirtilen adrese alınan kodu girin',
            codeDidNotCome: 'Kod gelmedi mi?',
            resendCode: 'Tekrar gönder',
            retryCall: 'Tekrar arama iste',
            timeLeft: ' {{value}} saniye sonra',
        },
        emailCode: {
            text: 'E-postayı girin',
            codeDidNotCome: 'Kod gelmedi mi? {{value}} saniye sonra tekrar gönder',
        },
        email: {
            text: 'E-postayı girin',
        },
    },
    form: {
        fields: {
            username: {
                placeholder: 'example@mail.com',
                label: 'E-mail',
                validations: {
                    require: 'alan doldurmak için gerekli',
                    email: 'Sıradan olmayan bir E-posta yazdınız',
                },
            },
            password: {
                label: 'şifre',
                validations: {
                    require: 'alan doldurmak için gerekli',
                },
            },
            rememberMe: {
                label: 'Beni hatırla',
            },
            phoneNumber: {
                label: 'Telefon',
                validations: {
                    phone: 'Önemli olmayan bir telefon numarası verdiniz mi',
                    require: 'alan doldurmak için gerekli',
                },
            },
            email: {
                label: 'email',
            },
            firstName: {
                label: 'Ad ve soyad',
            },
        },
    },
    links: {
        registration: 'kayıt',
        recoveryPassword: 'Şifreyi unuttum :(',
    },
    profileButton: 'Kabine',
    loginButton: 'Giriş / Kayıt',
    logoutButton: 'Çıkmak',
    submitButton: {
        next: 'sonraki',
        confirm: 'Onayla',
    },
    error: {
        phoneNumber: 'telefon numarası hatası',
        code: 'Yanlış doğrulama kodu, tekrar deneyin veya yeni bir tane isteyin',
    },
};

export default login;
